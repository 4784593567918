<template>
  <v-card :class="sensor === 'PPG' ? 'slots' : ''">
    <v-toolbar>
      <v-toolbar-title>{{ sensor }} Data</v-toolbar-title>

      <v-spacer />

      <v-toolbar-items>
        <v-spacer />

        <v-toolbar-items>
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn v-bind="props" icon="mdi-chevron-left" @click="tableRef?.$el.children[0].scrollTo(0, 0)" />
            </template>

            Scroll to beginning
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon="mdi-chevron-right"
                @click="tableRef?.$el.children[0].scrollTo(tableRef?.$el.offsetWidth, 0)"
              />
            </template>

            Scroll to end
          </v-tooltip>
        </v-toolbar-items>

        <v-switch
          v-if="getFieldItems(sensor, schema, config, 'measurement_slot_id').length === 1"
          class="px-4"
          color="primary"
          style="width: 100%"
          :model-value="getFieldValue(0, sensor, schema, config, 'measurement_channel_description_id') !== ''"
          @update:model-value="setChannelEnabled(0, sensor, schema, config, $event)"
        />
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text>
      <v-table ref="tableRef">
        <template #default>
          <thead>
            <tr>
              <th
                v-if="getFieldItems(sensor, schema, config, 'measurement_slot_id').length > 1"
                scope="col"
                class="text-primary text-left"
              >
                Slot
              </th>

              <th
                v-for="header in tableHeaders"
                :key="header.$ref"
                scope="col"
                style="width: 100px"
                class="text-primary text-left"
              >
                {{ header.title }}

                <v-tooltip v-if="getItemFieldRange(header.$ref)" location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props" size="small">mdi-information</v-icon>
                  </template>

                  <span>{{ getItemFieldRange(header.$ref) }}</span>
                </v-tooltip>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="slot in getFieldItems(sensor, schema, config, 'measurement_slot_id')" :key="slot.value">
              <td v-if="sensor === 'PPG'">
                <v-menu offset-y>
                  <template #activator="{ props }">
                    <v-btn v-bind="props" variant="text" class="px-0">
                      <div class="px-3">{{ slot.value }}</div>

                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>

                  <template #default>
                    <v-list>
                      <v-menu id="sm" offset-x open-on-hover max-width="150">
                        <template #activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            title="Copy values to another slot"
                            prepend-icon="mdi-content-copy"
                          />
                        </template>

                        <template #default>
                          <v-list>
                            <v-list-item
                              v-for="s in getFieldItems(sensor, schema, config, 'measurement_slot_id')"
                              :key="s.value"
                              link
                              :title="'Copy to slot ' + s.value"
                              @click="copyDataSlot(slot.value, s.value)"
                            />
                          </v-list>
                        </template>
                      </v-menu>

                      <v-menu offset-x open-on-hover max-width="150">
                        <template #activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            title="Move values to another slot"
                            prepend-icon="mdi-content-duplicate"
                          />
                        </template>

                        <template #default>
                          <v-list>
                            <v-list-item
                              v-for="s in getFieldItems(sensor, schema, config, 'measurement_slot_id')"
                              :key="s.value"
                              link
                              :title="'Move to slot ' + s.value"
                              @click="moveDataSlot(slot.value, s.value)"
                            />
                          </v-list>
                        </template>
                      </v-menu>

                      <v-list-item
                        link
                        class="text-error"
                        title="Clear this slot values"
                        prepend-icon="mdi-close"
                        @click="deleteDataSlot(slot.value, 'PPG', true)"
                      />
                    </v-list>
                  </template>
                </v-menu>
              </td>

              <td v-for="item in tableItems" :key="item.$ref">
                <template v-if="item.type === 'select'">
                  <v-autocomplete
                    density="compact"
                    :items="getFieldItems(sensor, schema, config, item.field)"
                    :disabled="isItemDisabled(slot.value, sensor, item.field)"
                    :model-value="getFieldValue(slot.value, sensor, schema, config, item.field)"
                    @update:model-value="setFieldValue(slot.value, sensor, schema, config, item.field, $event)"
                  />
                </template>

                <template v-else-if="item.type === 'textField'">
                  <v-text-field
                    type="number"
                    density="compact"
                    validate-on="lazy blur"
                    :suffix="
                      item.field === 'ppg_led_current_steps' &&
                      getFieldValue(slot.value, sensor, schema, config, 'ppg_led_current_steps') !== ''
                        ? 'mA'
                        : ''
                    "
                    :disabled="isItemDisabled(slot.value, sensor, item.field)"
                    :model-value="getItemFieldValue(slot.value, sensor, item.field)"
                    :rules="[(v: string) => setFieldValue(slot.value, sensor, schema, config, item.field, v)]"
                  />
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>

      <v-alert
        v-if="getChannelEnabled(8, 'TIMING', schema, config)"
        class="mt-2"
        density="compact"
        title="Slot 8 is configured to enable mandatory timing data stream since PPG and ECG/BIOZ are enabled"
        type="info"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { cloneDeep } from 'lodash-es'

  import { VTable } from 'vuetify/components'

  import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

  import {
    findMatchingMeasurement,
    getChannelEnabled,
    getFieldItems,
    getFieldValue,
    setChannelEnabled,
    setFieldValue,
  } from '#views/rdata/utilities'

  import { RdataMeasurement, RdataParameter } from '#types'

  @Component
  class EditingTable extends Vue {
    @Prop() public config!: any
    @Prop() public schema!: any

    @Prop() public sensor!: string

    @Prop() public disabled!: boolean

    public readonly getFieldItems = getFieldItems
    public readonly getFieldValue = getFieldValue
    public readonly setFieldValue = setFieldValue

    public readonly getChannelEnabled = getChannelEnabled
    public readonly setChannelEnabled = setChannelEnabled

    @Ref('tableRef') public readonly tableRef: VTable | undefined = undefined

    public get tableItems() {
      return (
        this.tableColumns.map((param: { title: string; $ref: string }) => {
          return {
            ...param,
            field: param.$ref.split('/').at(-1),
            type:
              this.schema.definitions[param.$ref.split('/').slice(-1)[0]]?.properties?.value?.oneOf ||
              this.schema.definitions[param.$ref.split('/').slice(-1)[0]]?.properties?.value?.anyOf
                ? 'select'
                : 'textField',
          }
        }) || []
      )
    }

    public get tableHeaders() {
      return this.tableColumns.map((param: { title: string; $ref: string }) => {
        if (param.title.toLowerCase().startsWith(this.sensor.toLowerCase())) {
          return {
            ...param,
            title: param.title.replace(new RegExp(`^${this.sensor}: `, 'i'), ''),
          }
        } else if (param.title.toLowerCase().startsWith('measurement')) {
          return {
            ...param,
            title: param.title.replace(/^Measurement: /i, ''),
          }
        }
      })
    }

    private get tableColumns() {
      return this.schema?.properties?.data_collections?.items?.properties?.measurements?.items?.properties?.parameters?.items?.oneOf?.filter(
        (param: { title: string; $ref: string }) =>
          (!param.title.toLowerCase().includes('monitor') &&
            param.title.toLowerCase().startsWith(this.sensor.toLowerCase())) ||
          (param.title.toLowerCase().startsWith('measurement') &&
            !param.title.toLowerCase().includes('data format id') &&
            param.title !== 'Measurement: Slot ID'),
      )
    }

    public copyDataSlot(fromSlot: number, toSlot: number) {
      const typeSchema = this.schema.definitions['measurement_channel_description_id']

      const slotSchema = this.schema.definitions.measurement_slot_id

      let measurementData: RdataMeasurement | undefined = findMatchingMeasurement(
        fromSlot,
        'PPG',
        slotSchema,
        typeSchema,
        this.config,
      )

      if (measurementData) {
        this.deleteDataSlot(toSlot, 'PPG')

        const newMeasurementData = cloneDeep(measurementData)

        newMeasurementData.name = newMeasurementData.name.replace(`Slot ${fromSlot}`, `Slot ${toSlot}`)

        const newParameterData = newMeasurementData.parameters.find(
          (p: RdataParameter) =>
            p.length === slotSchema?.properties?.length.default &&
            p.class_id === slotSchema?.properties?.class_id.default &&
            p.param_id === slotSchema?.properties?.param_id.default &&
            p.value === fromSlot,
        )

        if (newParameterData) {
          newParameterData.value = toSlot
          newParameterData.name = newParameterData.name.replace(`Slot ID - ${fromSlot}`, `Slot ID - ${toSlot}`)
        }

        this.config.data_collections[0].measurements.push(newMeasurementData)
      }
    }

    public moveDataSlot(slot: number, newSlot: number) {
      const typeSchema = this.schema.definitions['measurement_channel_description_id']

      const slotSchema = this.schema.definitions.measurement_slot_id

      let measurementData = findMatchingMeasurement(slot, 'PPG', slotSchema, typeSchema, this.config)

      if (measurementData) {
        this.deleteDataSlot(newSlot, 'PPG')

        measurementData.name = measurementData.name.replace(`Slot ${slot}`, `Slot ${newSlot}`)

        const parameterData = measurementData.parameters.find(
          (p: RdataParameter) =>
            p.length === slotSchema?.properties?.length.default &&
            p.class_id === slotSchema?.properties?.class_id.default &&
            p.param_id === slotSchema?.properties?.param_id.default &&
            p.value === slot,
        )

        if (parameterData) {
          parameterData.value = newSlot
          parameterData.name = parameterData.name.replace(`Slot ID - ${slot}`, `Slot ID - ${newSlot}`)
        }
      }
    }

    public deleteDataSlot(slot: number, sensor: string, disabled?: boolean) {
      const typeSchema = this.schema.definitions['measurement_channel_description_id']

      const slotSchema = this.schema.definitions.measurement_slot_id

      let measurementData = findMatchingMeasurement(slot, sensor, slotSchema, typeSchema, this.config)

      if (measurementData) {
        this.config.data_collections[0].measurements.splice(
          this.config.data_collections[0].measurements.indexOf(measurementData),
          1,
        )

        if (disabled && !getChannelEnabled(undefined, 'PPG', this.schema, this.config)) {
          // If disabling channel and no more PPG channels enabled then make sure no timing channel set

          setChannelEnabled(8, 'TIMING', this.schema, this.config, false)
        }
      }
    }

    public isItemDisabled(slot: number, sensor: string, field: string) {
      const channelDescriptionIdValue = this.getFieldValue(
        slot,
        sensor,
        this.schema,
        this.config,
        'measurement_channel_description_id',
      )

      const channelDescriptionIdIsEmpty = channelDescriptionIdValue === ''

      if (this.disabled) {
        return true
      } else if (sensor !== 'PPG') {
        return channelDescriptionIdIsEmpty
      } else if (sensor === 'PPG' && channelDescriptionIdIsEmpty && field !== 'measurement_channel_description_id') {
        return true
      }

      // PPG specific fields
      if (sensor === 'PPG') {
        const hardwareTypeIsOreo = this.config.hardware_type === 'oreo'

        const hardwareTypeIsGen3 = this.config.hardware_type === 'gen2x'

        // Disable cell if the Channel Description ID field is empty or PPG1 PD value is 0 (i.e. 'None' is selected)
        if (field === 'ppg_ppg1_adc_range' || field === 'ppg_ppg1_dac_offset') {
          return (
            channelDescriptionIdIsEmpty ||
            this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_ppg1_pd_bitmask') === 0
          )
        }
        // Disable cell if the Channel Description ID field is empty or PPG2 PD value is 0 (i.e. 'None' is selected)
        else if (field === 'ppg_ppg2_adc_range' || field === 'ppg_ppg2_dac_offset') {
          return (
            channelDescriptionIdIsEmpty ||
            this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_ppg2_pd_bitmask') === 0
          )
        }
        // Disable cell if:
        // - Channel Description ID field is empty or
        // - AGC version value is 0 (i.e. 'Disabled' is selected) or
        // - Channel Description ID value is 4 (i.e. 'PPG: Ambient' is selected) and hardware type is Oreo or
        // - Channel Description ID value is 9 (i.e. 'PPG: Ambient' is selected) and hardware type is Gen3
        else if (
          field === 'ppg_agc_target_level' ||
          field === 'ppg_agc_start_deviation_level' ||
          field === 'ppg_agc_stop_deviation_level' ||
          field === 'ppg_agc_maximum_current'
        ) {
          return (
            channelDescriptionIdIsEmpty ||
            this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_agc_version') === 0 ||
            (channelDescriptionIdValue === 4 && hardwareTypeIsOreo) ||
            (channelDescriptionIdValue === 9 && hardwareTypeIsGen3)
          )
        }
        // Disable cell if:
        // - Channel Description ID field is empty or
        // - Channel Description ID value is 4 (i.e. 'PPG: Ambient' is selected) and hardware type is Oreo or
        // - Channel Description ID value is 9 (i.e. 'PPG: Ambient' is selected) and hardware type is Gen3
        else if (
          field === 'ppg_led_current_range' ||
          field === 'ppg_led_current_steps' ||
          field === 'ppg_led_settling_time' ||
          field === 'ppg_agc_version' ||
          field === 'ppg_ambient_light_cancel'
        ) {
          return (
            channelDescriptionIdIsEmpty ||
            (channelDescriptionIdValue === 4 && hardwareTypeIsOreo) ||
            (channelDescriptionIdValue === 9 && hardwareTypeIsGen3)
          )
        }
        // Disable cell if Ambient light cancellation value is other than 0 (i.e. something else than 'On' is selected)
        // or Channel Description Id field value is 4 (i.e. 'PPG: Ambient' is selected) and hardware type is Oreo
        // or Channel Description Id field value is 9 (i.e. 'PPG: Ambient' is selected) and hardware type is Gen3
        else if (field === 'ppg_ambient_light_cancel_method') {
          return (
            this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_ambient_light_cancel') !== 0 ||
            (channelDescriptionIdValue === 4 && hardwareTypeIsOreo) ||
            (channelDescriptionIdValue === 9 && hardwareTypeIsGen3)
          )
        }
      }

      return false
    }

    public getItemFieldRange($ref: string): string {
      const hasMinMax =
        this.schema.definitions[$ref.split('/').slice(-1)[0]]?.properties?.value?.minimum !== undefined &&
        this.schema.definitions[$ref.split('/').slice(-1)[0]]?.properties?.value?.maximum !== undefined

      return hasMinMax
        ? `Value range: ${this.schema.definitions[$ref.split('/').slice(-1)[0]]?.properties?.value?.minimum} - ${this.schema.definitions[$ref.split('/').slice(-1)[0]]?.properties?.value?.maximum}`
        : ''
    }

    public getItemFieldValue(slot: number, sensor: string, field: string) {
      if (field !== 'ppg_led_current_steps') {
        return this.getFieldValue(slot, sensor, this.schema, this.config, field)
      } else {
        // LED current steps is special field since it needs adjusting two fields
        return this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_led_current_steps') !== ''
          ? this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_led_current_steps') *
              (((this.getFieldValue(slot, sensor, this.schema, this.config, 'ppg_led_current_range') + 1) * 32) / 256)
          : ''
      }
    }
  }

  export default toNative(EditingTable)
</script>

<style lang="scss" scoped>
  .slots {
    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background: rgb(var(--v-theme-surface));
    }
  }

  :deep(table) {
    th,
    td {
      min-width: 100px;
      padding: 0 4px !important;
    }
  }

  :deep(.v-input) {
    input,
    .mdi-menu-down {
      margin: -3px !important;
    }

    .v-field,
    .v-text-field__suffix {
      padding-right: 4px !important;
    }

    .v-field__input {
      padding: 8px 0 8px 8px !important;
    }

    .v-text-field__suffix__text {
      margin-bottom: 2px !important;
    }

    .v-autocomplete__selection-text {
      overflow: visible;
    }

    .v-text-field__suffix,
    .v-autocomplete__selection-text {
      font-size: 12px;
    }
  }

  :deep(.v-text-field) {
    min-width: 100%;
  }

  :deep(.v-autocomplete) {
    width: max-content;
    min-width: 100%;
  }

  :deep(.v-table__wrapper) {
    padding-bottom: 12px;
  }
</style>
