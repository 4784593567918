<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ sensor }} Monitoring</v-toolbar-title>
    </v-toolbar>

    <v-card-text class="pa-8">
      <v-autocomplete
        hide-details
        label="Monitoring mode"
        density="compact"
        no-data-text="No matching items"
        :disabled="disabled"
        :items="getFieldItems(sensor, schema, config, sensor.toLowerCase() + '_monitor')"
        :model-value="getMonitoringEnabled(sensor, schema, config, sensor.toLowerCase() + '_monitor') as number"
        @update:model-value="setMonitoringEnabled(sensor, schema, config, sensor.toLowerCase() + '_monitor', $event)"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { getFieldItems, getMonitoringEnabled, setMonitoringEnabled } from '#views/rdata/utilities'

  @Component
  class MonitoringMode extends Vue {
    @Prop() public config!: any
    @Prop() public schema!: any

    @Prop() public sensor!: string

    @Prop() public disabled!: boolean

    public readonly getFieldItems = getFieldItems

    public readonly getMonitoringEnabled = getMonitoringEnabled
    public readonly setMonitoringEnabled = setMonitoringEnabled
  }

  export default toNative(MonitoringMode)
</script>
