export interface Task {
  uid: string
  status: string

  taskName: string

  createdAt: string
  startedAt: string
  stoppedAt: string

  parameters: TaskParameters

  initiatingUserEmail: string

  info: {
    name?: string
    description?: string
    preprocessingOutput?: {
      generateUsersSample: Sample
    }
    preProcessingException?: string
  }
}

export interface TaskDetails extends Task {
  batchJobs: BatchJob[]
}

export interface TaskSettings {
  file?: any

  info: {
    name?: string
    description?: string
  }

  taskName?: string

  parameters: TaskParameters

  overrideSteps?: string[]
}

export interface TaskParameters {
  // Always required
  maxUsers: string
  appFlavor?: string
  fwVersions?: string

  blacklistedLabels?: string
  analyzeSampleMode: TaskConfigModes

  // For all commands
  inputOptions: string
  inputCommand: string
  inputCommandBranch: string

  // Other than tlog command
  inputDays?: string

  // Used only for tlog command
  fwVersionListId?: string

  // Used only in the random mode
  osSplit?: string
  deviceModels?: string[]

  // used in the random mode and for factory jzlog in the select mode
  hwType?: string

  // Used only in the select mode
  whitelistedFilters?: string

  // Used only in the upload mode
  sampleId?: string
  samplePath?: string
  sampleCount?: number

  // Used in random and select modes
  blacklistedFilters?: string

  // Used in the scheduled mode only
  taskRuleSetId?: string

  // Used in select mode, factory jzlog
  factory?: string
  testPhase?: string
  timestampFrom?: string
  timestampTo?: string

  // Used for factory jzlog in the scheduled select mode
  samplePeriodDays?: number
}

export interface Sample {
  path: string
  file: string
}

export interface Result {
  key: string
  lastModified: string
  size: number
  type: string
}

export interface Warning {
  data: any
  details: string[]
  key: string
  message: string
  size: number
  type: string
}

export interface Log {
  ingestionTime: number
  message: string
  timestamp: number
}

export interface Preview {
  fw: string
  os: string
  uuid: string
}

export interface File {
  prefix: string
  bucket: string
  saveAs?: string
}

export interface BatchJob {
  uid: string
  jobSettings: {
    jobName: string
    containerOverrides: {
      environment: { name: string; value: string }[]
    }
    dependsOn: { jobId: string }[] | []
  }
  jobType: string
  status: string
}

export enum TaskConfigModes {
  RERUN = 'rerun',
  RANDOM = 'random',
  SELECT = 'select',
  UPLOAD = 'upload',
  SCHEDULED = 'scheduled',
}

export enum JzlogSources {
  DEBUG = 'debug',
  FACTORY = 'factory',
  STAGING = 'staging',
  RELEASE = 'release',
  RESEARCH = 'research',
  EXPERIMENTAL = 'experimental',
}
